// Here you can add other styles
//.d-md-down-none{color:#ff004c;}
.c-sidebar-brand-full {
  color: gray("100");
  // background-color: #0b4b68;
}
  
.light-buttons {
  color: #0b4b68;
}

.form-control{
  color: black;
}
  
.quantity-filter {
  margin-bottom: 1rem;
  font-weight: bold;
}

.c-sidebar-brand-whole {
  background-color: #0b4b68;
}
.MuiDataGrid-columnsContainer {
  background-color: #0b4b68;
  color: #ffffff;
}

// .MuiDataGrid-root .MuiDataGrid-columnHeader{
//   padding: 0px
// }
.MuiDataGrid-dataContainer{
  font-size: smaller !important;
  @media (min-width: 900px) {
    font-size: small !important;
  };
  @media (min-width: 1120px) {
    font-size: medium !important;
  };
}

.MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer{
  padding: 0px !important;
  // font-size: small;
}
// font-size: large;
// @media (min-width: 340px) {
//   font-size: x-large !important;
// };
// @media (min-width: 576px) {
//   font-size: xx-large !important;
// };
.MuiDataGrid-columnHeader{
  padding: 0px 5px !important;
  font-size: xx-small !important;
  @media (min-width: 950px) {
    font-size: smaller !important;
  };
  @media (min-width: 1120px) {
    font-size: small !important;
  };
}
.MuiIconButton-sizeSmall{
  font-size: xx-small !important;
  @media (min-width: 950px) {
    font-size: smaller !important;
  };
  @media (min-width: 1120px) {
    font-size: small !important;
  };
}

.headlines {
  color: #0b4b68;
}
.MuiDataGrid-colCellCheckbox .MuiSvgIcon-root{
  color: #ffffff;
}

.MuiDataGrid-root .MuiDataGrid-iconSeparator{
  opacity: 0;
}
.prefix__cls-1 {
  fill:#468532;
}

.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1{
  font-weight: bold;
}

.MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded {
  border-radius: 15px;
}
.MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer .MuiIconButton-label {
  color: white;
}

.modal-header .close {
  color: white;
}
